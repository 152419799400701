import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import Hero from '../components/Hero';
import StaticLayout from '../components/StaticLayout';
import integrationListImg from '../public/images/integrations/list.png';
import collaborationImg from '../public/images/illustrations/collaboration.svg';
import mentorImg from '../public/images/illustrations/mentor.svg';
import awardsCloudImg from '../public/images/home/awards_cloud.png';
import { IdeaTextCard } from '../components/IdeaCard/IdeaCard';
import { homeExamples } from '../data/homeExamples';
import { GetStaticProps } from 'next';

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      homeExamples: homeExamples,
    }, // will be passed to the page component as props
  };
};

const Home: React.FC<any> = ({ homeExamples }) => (
  <StaticLayout title="Lampant - Idea and Innovation Management Platform for Communities">
    <Hero />

    {/* BLOCK WITH EXAMPLES */}

    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap items-top justify-between lg:space-x-4 py-4">
        <div className="w-full lg:w-1/5">
          <h3 className="font-medium text-indigo-400 underline decoration-2">
            For digital communities
          </h3>
          <p className="text-gray-700 text-sm">
            Running a DAO or community on Slack or Discord but need a place to
            organize ideation and decision making?
          </p>
        </div>
        <div className="w-full lg:w-1/5">
          <h3 className="font-medium text-indigo-400 underline decoration-2">
            For companies
          </h3>
          <p className="text-gray-700 text-sm">
            Tired of using a gazillion spredsheets to organize all your
            company's ideas?
          </p>
        </div>
        <div className="w-full lg:w-1/5">
          <h3 className="font-medium text-indigo-400 underline decoration-2">
            For NGOs
          </h3>
          <p className="text-gray-700 text-sm">
            Need an influx of creativity and a place to discover how to tackle
            the next big challenge?
          </p>
        </div>
        <div className="w-full lg:w-1/5">
          <h3 className="font-medium text-indigo-400 underline decoration-2">
            For any group
          </h3>
          <p className="text-gray-700 text-sm">
            A team, a department, an organization... If it's a group of people
            sharing ideas, Lapant is the right choice.
          </p>
        </div>
      </div>
    </div>

    <div className="py-24 bg-indigo-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
            Limitless
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Ideas and suggestions for anything. Literally.
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            You decide the topics and themes. Your community's members will do
            the rest.
          </p>
        </div>

        <div className="mt-12">
          <div className="hidden md:block md:masonry-4 before:box-inherit after:box-inherit space-y-6 my-6 w-full">
            {homeExamples.map((idea) => (
              <IdeaTextCard
                key={idea.id}
                color={idea.topic.color}
                idea={idea}
                className="hover:scale-110 transition duration-500 ease-in-out transform hover:-translate-y-1"
              />
            ))}
          </div>
          <div className="text-center mt-12">
            <Link href="/register?ref=wall">
              <a className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-800 md:py-4 md:text-lg md:px-10">
                Join and make it your own
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pb-12 lg:py-12">
          <div className="flex flex-wrap items-center">
            <div className="-mb-1 w-full lg:w-1/2 ">
              <Image src={mentorImg} alt="Idea Process" />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="lg:text-center">
                <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                  Actionable Ideas
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Share. Discuss. Implement. Repeat.
                </p>
                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                  Share your ideas to get feeback and insights from your fellow
                  members. Iterate until it's approved and move ahead.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="py-16 bg-indigo-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center flex-wrap">
          <div className="lg:text-center w-full lg:w-1/2">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
              Innovation is a team sport
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              An Idea is often an input... for another Idea
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              You couldn't invent a car without inventing wheels, gas or the
              internal combustion engine first.
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Foster connections between your employees to improve{' '}
              <span className="font-medium">outcomes</span>, while increasing{' '}
              <span className="font-medium">engagement</span> and{' '}
              <span className="font-medium">collaboration</span>.
            </p>
          </div>
          <div className="mx-auto w-full lg:w-1/2">
            <Image className="" src={collaborationImg} alt="Idea Cloud" />
          </div>
        </div>
      </div>
    </div>

    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center flex-wrap">
          <div className="mt-12 w-full lg:w-1/2 mx-auto">
            <Image className="" src={awardsCloudImg} alt="Idea Cloud" />
          </div>
          <div className="lg:text-center w-full lg:w-1/2">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
              Engage and reward
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Keep people engaged and recognize their input
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Nothing makes people more invested than recognizing their value
              and their input. Reward individuals virtually. Or in any way you
              like.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* BLOCK WITH FEATURES */}

    <div className="py-24 bg-indigo-100">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
            Innovation at your fingertips
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            A better way to move your community forward
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Lampant offers an innovation platform like no other, allowing your
            community to excel by leveraging your own people and their ideas.
          </p>
        </div>

        <div className="mt-16">
          <dl className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10">
            <div className="flex">
              <div className="shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-pink-500 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Get ideas from everyone
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Wherever your people are and whatever is their role, their
                  ideas can be very valuable.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Make good decisions
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Use data to drive your decision making, measure and follow up
                  on achievements and learnings.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-400 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Light the fire!
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Inspire interest in different topics, bring people together
                  and get the best outcomes.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Spark the conversation
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  The best ideas come out when people talk. Collaboration, from
                  a random thought to a concrete plan.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <div className="py-24 bg-indigo-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
            Integrate
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Innovation everywhere, anyware and somewhere in between
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Integrate with several tools to take your ideas to new heights. Make
            it easier to submit and participate with a few clicks.
          </p>
        </div>
        <div className="mt-12 w-full lg:w-1/2 mx-auto">
          <Image className="" src={integrationListImg} alt="Integrations" />
        </div>
      </div>
    </div>

    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">
            Ready to unlock your community's innovative power?
          </span>
        </h2>
        <div className="mt-8 lex lg:mt-0 lg:shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link href="/register?ref=bottom">
              <a className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                Register
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </StaticLayout>
);

export default Home;

import Link from 'next/link';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { BsFillCaretUpFill } from 'react-icons/bs';
import { useSession } from '../../lib/auth';
import IdeaCardState from '../IdeaCardState';
import { IdeaCardProps } from './types';
import { useApiClient } from '../../lib/api';
import UserAvatar from '../UserAvatar';

const IdeaTextCard: React.FC<IdeaCardProps> = ({
  idea,
  className,
  variant = 'full',
}) => {
  return (
    <div
      className={`bg-${idea.topic.color}-500 hover:bg-${idea.topic.color}-600 ${className} p-4 break-inside space-y-2 rounded-md`}
    >
      <div className="flex items-center justify-between">
        <div className="font-medium text-gray-100 flex items-center space-x-2">
          <img
            src={`/images/icons/topics/${idea.topic.icon}.svg`}
            className="h-5 w-5"
            alt={idea.topic.name}
          />
          <span className="text-sm">{idea.topic.name}</span>
        </div>

        <UserAvatar user={idea.user} size="small" />
      </div>
      <div className="whitespace-pre-wrap">
        <p
          className={`${variant === 'full' ? 'text-xl' : 'text-lg'} text-${
            idea.topic.color
          }-100 font-bold`}
        >
          {idea.title}
        </p>
        {variant === 'full' ? (
          <p className={`text-base text-${idea.topic.color}-200`}>
            {idea.pitch}
          </p>
        ) : null}
      </div>
      <div
        className={`flex flex-wrap text-xs text-${idea.topic.color}-300 space-x-2`}
      >
        {idea.tags && idea.tags.map((tag) => <span key={tag}>#{tag}</span>)}
      </div>
      <div className="flex items-center justify-between">
        <span className="text-gray-100 text-xs whitespace-nowrap">
          {idea.upvoters_count} votes
        </span>
      </div>
    </div>
  );
};

const IdeaCard: React.FC<IdeaCardProps> = ({
  idea,
  color,
  withTopicIcon,
  onUpvote,
  className,
}) => {
  const { user } = useSession();
  const api = useApiClient();
  const { addToast } = useToasts();

  const isOwner = idea.user.id === user.id;
  const isVoter = idea.is_upvoter;
  const canVote = !isOwner && !isVoter;

  const handleUpvote = async () => {
    if (!canVote) return;

    try {
      await api.post(
        `/organizations/${user.organization.slug}/ideas/${idea.id}/upvote`
      );

      onUpvote(idea.id);
    } catch (error) {
      addToast('There was an error upvoting the idea.', {
        appearance: 'error',
      });
    }
  };

  let ideaStyle = 'text-gray-300';

  if (canVote) {
    ideaStyle = 'text-green-500 hover:text-green-200 cursor-pointer';
  } else if (isVoter) {
    ideaStyle = 'text-yellow-500';
  }

  return (
    <div
      className={`text-gray-700 hover:text-gray-600 hover:bg-opacity-40 shadow-sm bg-${color}-100 text-left ${className}`}
    >
      <div className="flex items-center min-h-full">
        <div className="mr-2 p-2 h-100 w-1/12 text-center">
          <div className={`text-lg ${ideaStyle}`}>
            <BsFillCaretUpFill className="mx-auto" onClick={handleUpvote} />
          </div>
          <p className="text-xs font-medium">{idea.upvoters_count}</p>
        </div>

        <Link href={`/org/${user.organization.slug}/ideas/${idea.id}`}>
          <a className="w-9/12 py-2">
            <div className="flex items-center">
              {withTopicIcon ? (
                <div className="pr-4">
                  <div className="w-6 h-6">
                    <img
                      src={`/images/icons/topics/${idea.topic.icon}.svg`}
                      alt={idea.topic.name}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              <div>
                <div className="cursor-pointer h-full text-md font-bold ">
                  {idea.title}
                </div>
                <div className="cursor-pointer h-full text-sm">
                  {idea.pitch}
                </div>
              </div>
            </div>
          </a>
        </Link>
        <div className="w-2/12">
          <IdeaCardState state={idea.state} />
        </div>
        <div className="w-1/12 mr-2">
          <UserAvatar user={idea.user} size="small" />
        </div>
      </div>
    </div>
  );
};

export { IdeaTextCard };

export default IdeaCard;
